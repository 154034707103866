export const fadeInOutVariant = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
}

export const commonAnimationProps = {
  initial: "initial",
  animate: "animate",
  exit: "exit",
}
